/* Overall page background (optional) */
body {
  background-color: #fcf9f4; 
  margin: 0;
  font-family: 'Gothic A1';
  color: black;
}

/* Container for the product layout */
.product-page {
  display: flex;
  flex-wrap: wrap; /* allows responsive behavior */
  max-width: 1200px;
  justify-content: center;
  margin: 2rem auto;
  gap: 1rem;
  padding: 2rem;
  margin-top: 86px;
}

.product-features {
  /* flex: 1 1 400px; */
  display: flex;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem;
}

/* Left side: product image/gallery */
.product-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

/* Right side: product info */
.product-details {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

/* Title */
.product-title {
  font-size: 2rem;
  margin-top: 1rem;
  font-weight: bold;
  color: black;
}

/* Price & rating container */
.price-rating {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Price text */
.product-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fc0a7e;
}

.base-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fc0a7e;
}

/* Form for product options */
.product-options {
  display: flex;
  flex-direction: column;
}

.add-1-feed {
 margin-left: 2rem;
} 

.product-options label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
}

.product-options select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  max-width: 215px;
}

/* Targets the input inside the datepicker wrapper */
.react-datepicker-wrapper,
.react-datepicker__input-container input {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: black;
}

.react-datepicker__input-container input::placeholder {
  color: black;
  opacity: 1;
}


/* Buttons (Add to Cart & Buy Now) */
.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

/* 'Add to Cart' button styles */
.request-info {
  background-color: white; 
  border: 1px solid rgb(116, 116, 116);
  color: black;
  transition: background-color 0.3s;
}

.request-info:hover {
  background-color: white; 
  border: 1px solid black;
  color: black;
}

/* 'Buy Now' button styles */
.buy-now {
  background-color: #fc0a7e;
  color: #fff;
}

.buy-now:disabled {
  cursor: not-allowed;
}

.buy-now:hover {
  background-color: #c10a62;
  color: #fff;
}

/* Order Details and Order Number */
.order-details {
  margin: 0;
  padding: 1rem;
  border: 1px solid rgb(116, 116, 116);
}

.order-details h1 {
  margin-top: 1rem;
  color: #fc0a7e;
  font-size: 1.5rem;
  font-weight: bold;  
  line-height: 2rem;
}

.order-details h2 {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: normal;  
}

.order-details h3 {
  margin: 0 auto;
  font-size: 1rem;
  font-weight: normal;
}

/* "What's Included" box */
.included-features {
  background-color: #fff;
  border-radius: 6px;
  max-width: 400px;
}

.included-features h3 {
  margin-top: 0;
  font-size: 1.5rem;
}

.included-features ul {
  margin: 0;
  font-size: 1rem;
  padding-inline-start: 1rem;
  line-height: 2rem;
}


@media (min-width: 1024px) {

}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-product-image {
    min-width: 400px;
  }
  .product-details {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .main-product-image {
    min-width: 300px;
  }
  .product-details {
    max-width: 500px;
  }
}


.main-image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.main-product-image {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
  max-width: 600px;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, border 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.thumbnail.active {
  border: 1px solid #fc0a7e;
  transform: scale(1.1);
}
