/* Request Info Form Overlay */
.request-info-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Form Header */
.request-info-form h3 {
  margin-top: 0;
  text-align: center;
  font-size: 1.5rem;
}

/* Input Fields */
.request-info-form label {
  display: block;
  font-weight: bold;
  margin-top: 2rem;
}

.request-info-form input,
.request-info-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.25rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Textarea Customization */
.request-info-form textarea {
  min-height: 100px;
  resize: vertical;
}

.request-info-form .submit-message {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  font-size: 1rem;
  color: #fc0a7e;
}

/* Submit Button */
.request-info-form .submit-button {
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #fc0a7e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.request-info-form .submit-button:hover {
  background-color: #c10a62;
}

/* Form Close Button */
/* Close Button */
.request-info-form .close-form-button {
  position: fixed;
  top: 0.5rem;
  right: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  line-height: normal;
}

/* Overlay Background */
.request-info-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
