.polaroid-gallery {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 0;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
}

.polaroid {
  border: 2px solid #F5F5F5;
  position: relative;
  background: #F5F5F5;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}


.visible .polaroid {
  animation: polaroid-enter 3s ease forwards;
}

.polaroid img {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.polaroid-caption {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0px;
  font-family: 'Gothic A1';
  font-size: 12px;
  color: #444;
}

.polaroid-gallery.visible .polaroid {
  animation-play-state: running;
}

/* Individual animation delays for the polaroids */
.polaroid:nth-child(1) {
  animation-delay: 0.1s;
}

.polaroid:nth-child(2) {
  animation-delay: 0.2s;
}

.polaroid:nth-child(3) {
  animation-delay: 0.3s;
}

.polaroid:nth-child(4) {
  animation-delay: 0.4s;
}

.polaroid:nth-child(5) {
  animation-delay: 0.5s;
}
.polaroid:nth-child(6) {
  animation-delay: 0.6s;
}
.polaroid:nth-child(7) {
  animation-delay: 0.7s;
}
.polaroid:nth-child(8) {
  animation-delay: 0.8s;
}

/* Animation keyframes */
@keyframes polaroid-enter {
  0% {
    transform: rotate(calc(var(--rotation, -15deg)));
  }
  100% {
    transform: rotate(calc(var(--rotation, 15deg)));
  }
}


@media (min-width: 1025px) {
  .polaroid {
    width: 220px;
  }

  .polaroid img {
    height: 170px;
  }

  .polaroid-caption {
    font-size: 14px;
  }
  .polaroid-gallery {
    gap: 3rem;
  }
}

@media (max-width: 1024px) {
  .polaroid {
    width: 200px;
  }

  .polaroid img {
    height: 153px;
  }

  .polaroid-caption {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .polaroid {
    width: 150px;
  }

  .polaroid img {
    height: 115px;
  }

  .polaroid-caption {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .polaroid {
    width: 150px;
  }

  .polaroid img {
    height: 115px;
  }

  .polaroid-caption {
    font-size: 12px;
  }
}