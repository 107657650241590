html, body {
  margin: 0;
  padding: 0;
  width: 100%;  
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Gothic A1';
}

main {
  flex: 1;
  margin-top: 0px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FFF;
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1000;
  background: transparent;
  margin-top: 0px;
}

.App-header h1 a {
  color: #FFF;
  line-height: 1;
  font-size: 3rem;
  margin: 0;
  background: transparent;
  font-family: 'Lobster';
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.header-dropdown-menu {
  position: fixed; /* Fixed to the viewport */
  top: 0px;
  right: 0;
  padding: 80px 20px;
  height: 100vh;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  z-index: 10;
  transform: translateX(100%); /* Hidden off-screen initially */
  visibility: hidden; /* Invisible initially */
  opacity: 0; /* Fully transparent initially */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
  list-style-type: none;
}

.header-dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  border:none;
  border-radius: 0;
  transform: translateX(0); /* Bring menu into view */
}

.header-dropdown-menu ul {
  margin-top: 90px;

}

.header-dropdown-menu li {
  padding: 5px;
  border-bottom: 1px solid #eaeaea; /* Optional separator */
}

.header-dropdown-menu li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.header-dropdown-menu li a {
  display: block;
  padding: 20px 0px;
  text-decoration: none;
  color: #281F1A;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.header-dropdown-menu li a:hover {
  color: #6f8c8b;
}

.menu-button {
  display:none;
  background: none;
  position: relative;
  border: none;
  color: #FFF;
  font-size: 36px;
  cursor: pointer;
  z-index: 2000;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

}

.logo-image {
  height: auto;
  width: 100px;
  margin-right: auto;
}

/* #features {
  padding: 50px 20px;
  background: #fff;
  width: 30%;
  box-sizing: border-box;
}

.feature-list {
  display: flex;
  justify-content: space-around;
} */

footer {
  height: 10vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  padding: 20px;
  color: white;
  margin: 0;
}

/* .footer-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
}

.footer-button {
  background-color: transparent;
  color: white;
  border: none;
}

.footer-button h1 {
  font-size: 18px;
}

.footer-button p{
  font-size: 16px;
} */

footer p {
  margin: auto;
  font-size: 16px;
  font-family: 'Gothic A1';
}

/* .homepage {
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.homepage h1 {
  margin: 2rem 1rem;
  font-size: 2.5rem;
  font-family: 'Lobster Two';
  font-weight: normal;
  color: #281F1A;
}

.homepage h2 {
  margin-top: -2rem;
  margin-bottom: -1rem;
  font-size: 2rem;
  color: #6f8c8b;
  font-family: 'Roboto';
  text-decoration: overline;
}

.homepage h3{
  margin: 3rem auto;
  font-size: 1rem;
  color: #443c38;
  font-family: 'Poppins';
} */

.primary-button:hover {
  background-color: #6f8c8b;
}

.communication-tools {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1b;
  height: 100vh;
  margin: auto;
  z-index: 2;
}

.communication-tools-2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: auto;
  z-index: 2;
}

.text-section {
  justify-content: center;
  text-align: center;  
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: 'Gothic A1';
  width: auto;
  max-width: 800px;
}

.text-section h2 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  color: #a8a8a8;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.text-section p {
  color: #FFF;
  font-size: 38px;
  margin: 0;
  font-weight: 700;
  flex-wrap: wrap;
  max-width: 700px;
}

.image-section {
  display: flex;
  justify-content: center;
  height: auto;
  max-width: 100%;
  gap: 1rem;
}

.phone-image {
  max-width: 50%; /* Ensure each image fits within the section */
  height: auto;
  max-height: 500px;
  object-fit: contain;
}


.feature-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space between each feature */
  align-items: flex-start; /*  Ensures all columns start at the top */
  background-color: #fff;
  padding: 0;
  margin: 10vh auto;
  flex-wrap: wrap; /*  Makes it responsive */
  align-items: stretch; /*  Ensures all feature-content elements have equal height */
}

.feature-content {
  flex: 1; /*  Ensures equal width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /*  Ensures everything inside starts at the top */
  text-align: center;
  min-width: 100px;
  max-width: 400px;
  padding: 5px;
}

.feature-text{
  font-size: 18px;
  font-family: 'Gothic A1';
  font-weight: 700;
  color: black;
  text-align: center;
  margin: 10px 0;
}

.feature-paragraph{
  font-size: 16px;
  font-family: 'Gothic A1';
  font-weight: 400;
  color: #6b6b6b;
  margin-bottom: 10px;
  width: auto;
}

.feature-icon {
  margin-bottom: 10px;
}



.closing-section {
  text-align: center;
  padding: 15vh 0;
  background: linear-gradient(135deg, #fc0a7e 0%, #602bb6);
}

.closing-wrap {
  display: block;
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
}

.closing-wrap h2 {
  font-family: 'Gothic A1';
  font-weight: 700;
  font-size: 38px;
  color: #FFF;
  text-align: center;
}
.button-wrap {
  display: inline-flex;
  margin-top: 5vh;
  gap: 20px;
}

.book-now-button {
  background-color: #fc0a7e;
  border: none;
  padding: 20px 40px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
}

.book-now-button:hover{
    background-color: #ffffff;
}

.book-now-button:hover a{
  color:#443c38;
  text-decoration: none;
}

.book-now-button a {
  font-size: 18px;
  font-weight: 600;
  color: #FFF;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.outline-button {
  background-color: transparent;
  border: 2px solid rgba(255,255,255,0.5);
  padding: 16px 24px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.outline-button:hover{
  border: 2px solid #fff;
  text-decoration: none;
}

.outline-button a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.material-icons-outlined {
  font-size: 55px;
  color: #fc0a7e;
}

.communication-tools-brokenout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  padding-top: 5vh;
  margin: auto;
  z-index: 2;
  width: auto;
}

.communication-tools-brokenout-reverse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 0;
  padding-bottom: 5vh;
  margin: 0 auto;
  z-index: 2;
  width: auto;
}

.communication-tools-brokenout .text-section,
.communication-tools-2 .text-section {
  justify-content: center;
  text-align: left;  
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: auto; 
  flex-wrap: wrap;
  font-family: 'Gothic A1';
  width: auto;
}

.communication-tools-brokenout-reverse .text-section {
  justify-content: flex-end;
  text-align: left;  
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: auto;
  flex-wrap: wrap;
  font-family: 'Gothic A1';
  width: auto;
}

.communication-tools-brokenout .text-section h2,
.communication-tools-brokenout-reverse .text-section h2 {
  font-weight: 400;
  font-size: 16px;
  margin: 0 5px 0 0;
  color: #a8a8a8;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.communication-tools-2 .text-section h2 {
  font-weight: 400;
  font-size: 16px;
  margin: 0 5px 0 0;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.communication-tools-brokenout .text-section p,
.communication-tools-brokenout-reverse .text-section p {
  color: #1b1b1b;
  font-size: 38px;
  margin: 20px 0;
  margin-left: 0;
  line-height: normal;
  font-weight: 700;
  flex-wrap: wrap;
  max-width: 100%;
}

.communication-tools-2 .text-section p {
  color: #fff;
  font-size: 38px;
  margin: 20px 0;
  margin-left: 0;
  line-height: normal;
  font-weight: 700;
  flex-wrap: wrap;
  max-width: 100%;
}

.communication-tools-brokenout .text-section .p2,
.communication-tools-brokenout-reverse .text-section .p2{
  color: #6b6b6b;
  font-size: 18px;
  margin: 10px 0;
  font-weight: 400;
  flex-wrap: wrap;
  max-width: 100%;
}

.communication-tools-2 .text-section .p2{
  color: #fff;
  font-size: 18px;
  margin: 10px 0;
  font-weight: 400;
  flex-wrap: wrap;
  max-width: 95%;
}

.communication-tools-brokenout .text-section,
.communication-tools-brokenout .image-section {
  flex: 1; /* Each section takes up equal space */
  max-width: 50%; /* Prevent sections from stretching too much */
}


/* Container for the chat bubbles */
.prompt-gallery {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  margin: 4vw 0;
  padding: 20px;
  height: auto;
}

.prompt {
  background-color: #f0f0f0;
  color: #333;
  padding: 12px 18px;
  border-radius: 20px;
  max-width: 100%;
  word-wrap: break-word;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  align-self: flex-start; /* Left side */
  background-color: #fc0a7e;
  color: #fff;
}

.prompt::before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 10px;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #fc0a7e transparent transparent transparent;
}

.prompt-caption {
  font-size: 16px;
  line-height: 1.4;
}

/* Keyframes for Smooth Scrolling */
@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  from {
    transform: translateX(-50%); /* Start with the track off-screen to the left */
  }
  to {
    transform: translateX(0); /* Move the track back to its original position */
  }
}


@media (min-width: 1025px) {
  .header-dropdown-menu {
    width: 350px;
  }
  .communication-tools-brokenout-reverse {
    max-width: 70%;
  }
  .communication-tools-brokenout {
    max-width: 70%;
  }
  .communication-tools-2 {
    width: 70%;
  }
  .feature-wrap {
    width: 70%;
  }
  .feature-paragraph {
    width: 70%;
  }
}

@media ((min-width: 769px) and (max-width: 1024px)) {
  .header-dropdown-menu {
    width: 300px;
  }
  .communication-tools-brokenout-reverse {
    max-width: 70%;
  }
  .communication-tools-brokenout {
    max-width: 70%;
  }
  .communication-tools-2 {
    width: 70%;
  }
  .feature-wrap {
    width: 70%;
  }
  .feature-paragraph {
    width: 70%;
  }  
}

@media (max-width: 768px) {
  .App-header {
    padding: 1rem;
  }
  .header-dropdown-menu {
    width: 200px;
  }
  
  .feature-wrap {
    width: 100%;
  }
  .feature-text{
    width: 100%;
  }
  .feature-paragraph {
    width: 100%;
  }  
  /* .communication-tools-brokenout-reverse .text-section {
    justify-content: center;
    text-align: left;  
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 4vw;
    margin-right: 4vw;
    width: 90%;
  }
  .communication-tools-brokenout-reverse .text-section p {
    margin-left: 0;
  } */
}

@media (max-width: 480px) {
  .header-dropdown-menu {
    width: 200px;
  }
  /* .communication-tools-brokenout-reverse .text-section {
    justify-content: center;
    text-align: left;  
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 4vw;
    margin-right: 4vw;
    width: 90%;
  }
  .communication-tools-brokenout-reverse .text-section p {
    margin-left: 0;
    max-width: 100%;
  }
  .communication-tools-brokenout-reverse .text-section p,
  .communication-tools-brokenout .text-section p {
    font-size: 30px;
  } */

}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out;
}

.nav-wrap {
  display: none; 
}

/* Desktop Navigation - Show links horizontally */
@media (min-width: 1025px) {
  .nav-wrap {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  .nav-links {
    display: flex;
    list-style: none;
    gap: 20px;
    padding: 0;
    margin: 0;
  }

  .nav-links li {
    display: inline;
  }

  .nav-links a {
    text-decoration: none;
    color: #FFF;
    font-weight: normal;
    padding: 10px 15px;
    font-size: 20px;
    font-family: 'Gothic A1';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .nav-links a:hover {
    text-decoration: underline;
  }
}

/* Show menu button on tablets and smaller */
@media (max-width: 1024px) {
  .menu-button {
    display: block;
  }
}

/* Ensure nav links are always visible on desktop */
@media (min-width: 1025px) {

}

.text-section {
  opacity: 1;
  transform: translateY(40px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}
.text-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.closing-wrap{
  opacity: 1;
  transform: translateY(40px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}
.closing-wrap.visible {
  opacity: 1;
  transform: translateY(0);
}